body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  max-width: 100%;
  overflow-x: hidden;
}

.login {
  margin-top: 10% !important;
}

.btn  {
  height: 32px !important;
}

.container {
  margin-top: 20px;
}

.row {
  margin-bottom: 10px;
}

.light {
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.light:hover {
  opacity: 1 !important;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.navbar-nav a {
  color: #fff !important;
}

.form-control {
  margin-bottom: 10px;
}

.navbar-logo {
  width: 25%;
  height: 25%;
  padding: 10px;
}

.type-item {
  color: #fff !important;
  display: block;
  line-height: 25px;
  cursor: pointer;
  font-weight: 300;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.item-type {
  padding: 3px 3px 3px 0;
  border-radius: 3px;
}

.type-item:hover {
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  font-weight: 500;
}

.item-name {
  padding: 6px;
  background-color: #212529;
  color: #fff;
  text-transform: capitalize;
  opacity: 0.9;
}

.item-link {
  font-size: 12px;
  line-height: 15px !important;
}

.file-box {
  box-shadow:  2px 4px 0px rgba(0, 0, 0, .15) !important;
  -webkit-box-shadow: 0 2px 20px 1px rgba(0, 0, 0, .25) !important;
  padding: 25px !important;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.sidebar-header h3 {
  color: #fff;
  font-size: 17px !important;
  font-weight: 800;
}

#sidebar {
  padding: 12px;
  background-color: #212529;
  min-height: 100vh;
  min-width: 20%;
}

#content::before {
  width: 80%;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

#content {
  background-color: #fff !important;
}

.copyright-footer {
  font-size: 11px;
  color: #fff;
  position: absolute;
  bottom: 20px;
  position: fixed;
}

